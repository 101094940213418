<template>
  <v-container fluid>
    <v-card-actions>
      <v-row class="mt-1">
        <v-col>
          <ButtonAdicionar :temPermissao="true" to="/grupos/adicionar" />
        </v-col>
      </v-row>
    </v-card-actions>
    <v-row class="mt-4">
      <v-col>
        <v-data-table
          disable-filtering
          disable-sort
          :server-items-length="totalRegistros"
          :headers="cabecalho"
          :items="items"
          :options.sync="paginacao"
          :footer-props="{
            'items-per-page-options': [10, 20, 50],
          }"
          @update:options="carregarGrupos"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <table-btn-editar
              :temPermissao="
                $store.getters['usuario/temPermissao']('editar.tabelas')
              "
              :href="`/grupos/${item.id}/editar`"
            />
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import grupos from "@/api/grupos";

export default {
  components: {
    ButtonAdicionar: () => import("@/components/button/ButtonAdicionar"),
  },

  data() {
    return {
      totalRegistros: 0,
      cabecalho: [
        { text: "#", value: "id" },
        { text: "Descrição", value: "nome" },
        { text: "Ações", value: "actions", align: "right" },
      ],
      items: [],
      paginacao: {},
    };
  },

  methods: {
    async carregarGrupos() {
      try {
        this.$store.commit(`layout/SET_LOADING`, true);
        const resposta = await grupos.listar();

        this.items = resposta.data.data;
        this.totalRegistros = resposta.data.meta;
      } catch (e) {
        this.$snackbar.mostrar({ cor: "error", mensagem: this.$erroApi._(e) });
      } finally {
        this.$store.commit(`layout/SET_LOADING`, false);
      }
    },
  },
};
</script>
